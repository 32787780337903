(function () {

  // universal script for footer copyright
  const ascDomains = asc_domain_list;

  let domainName = window.location.hostname.replace(/\b(?:dlwww.|mlwww.|www.|dlcheckout.|mlcheckout.|checkout.|dlcheckout-|mlcheckout-|checkout-)\b/,'');

  const asc_copyright = "Copyright &#169; " + new Date().getFullYear() + ", American Safety Council, Inc. All Rights Reserved.";
  const certus_copyright = "Copyright &#169; " + new Date().getFullYear() + ", Certus. All Rights Reserved.";

  let copyrightText = "";
  if(ascDomains.includes(domainName)){
    copyrightText = asc_copyright;
  }
  else {
    copyrightText = certus_copyright;
  }

  if(document.querySelector('.copyright-text')){
    document.querySelector('.copyright-text').innerHTML = copyrightText;
  }

})();